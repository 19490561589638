// https://github.com/kentcdodds/kentcdodds.com/blob/main/app/utils/misc.tsx
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { useRouteError } from "@remix-run/react";

function getErrorMessage(error, fallback = "Unknown Error") {
  if (typeof error === "string") return error;
  if (error instanceof Error) return error.message;
  return fallback;
}

export function useCapturedRouteError() {
  const error = useRouteError();
  // Kent dodds used this to capture errors in sentry

  captureRemixErrorBoundaryError(error);
  return error;
}

export { getErrorMessage };
