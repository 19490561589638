import {
  ArrowLeftCircle,
  Rss,
  MessageCircle,
  BookOpen,
  Home,
} from "react-feather";
import { Link } from "@remix-run/react";

const links = [
  {
    name: "Help Documentation",
    href: "https://www.shopdeploy.com/docs",
    description: "Learn how to use Shopdeploy.",
    icon: BookOpen,
  },
  {
    name: "Contact Us",
    href: "/contact",
    description: "Need help? Send us a message.",
    icon: MessageCircle,
  },
  {
    name: "Home",
    href: "/",
    description: "Go back to the homepage.",
    icon: Home,
  },
  {
    name: "Blog",
    href: "/blog",
    description: "Read our latest news and articles.",
    icon: Rss,
  },
];

export const FourOhFour = () => {
  return (
    <div className="mx-auto w-full max-w-7xl px-6 pb-16 sm:pb-24 lg:px-8 mb-8">
      <div className="mx-auto  max-w-2xl text-center">
        <p className="text-base font-semibold leading-8 text-indigo-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          This page does not exist
        </h1>
        <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
      </div>
      <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
        <h2 className="sr-only">Popular pages</h2>
        <ul className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
          {links.map((link, linkIdx) => (
            <li key={linkIdx} className="relative flex gap-x-8 py-6">
              <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                <link.icon
                  className=" text-indigo-600"
                  aria-hidden="true"
                  size={24}
                />
              </div>
              <div className="flex-auto">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  <Link to={link.href}>
                    <span className="absolute inset-0" aria-hidden="true" />
                    {link.name}
                  </Link>
                </h3>
                <p className="mt-2 text-sm leading-6 text-gray-600">
                  {link.description}
                </p>
              </div>
              <div className="flex-none self-center">
                <ArrowLeftCircle
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </li>
          ))}
        </ul>
        <div className="mt-10 flex justify-center">
          <Link
            to={"/"}
            className="text-sm font-semibold leading-6 text-indigo-600"
          >
            <span aria-hidden="true">&larr;</span>
            Back to home
          </Link>
        </div>
      </div>
    </div>
  );
};
