/* eslint-disable react/prop-types */
import { isRouteErrorResponse, useParams } from "@remix-run/react";
import { getErrorMessage, useCapturedRouteError } from "~/utils/misc";

export function GeneralErrorBoundary({
  // eslint-disable-next-line react/prop-types
  defaultStatusHandler = ({ error }) => (
    <p>
      {error.status} {error.data} 1
    </p>
  ),
  statusHandlers,
  unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)} 1</p>,
}) {
  const error = useCapturedRouteError();
  const params = useParams();

  if (typeof document !== "undefined") {
    // console.error(error);
  }

  return (
    <div className="container mx-auto flex items-center justify-center pt-10 lg:p-20 text-h2">
      {isRouteErrorResponse(error)
        ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
            error,
            params,
          })
        : unexpectedErrorHandler(error)}
    </div>
  );
}
